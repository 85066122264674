.App {
    text-align: center;
    height: 100%;
}

.App-logo {
    animation     : App-logo-spin infinite 20s linear;
    height        : 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height      : 100vh;
    display         : flex;
    flex-direction  : column;
    align-items     : center;
    justify-content : center;
    font-size       : calc(10px + 2vmin);
    color           : white;
}

.App-link {
    color: #61dafb;
}

head, body {
    height: 100%;
}

h2.page-title {
    text-align: left;
    margin-left: 50px;
    margin-top: 20px;
}

div#root {
    height: 100%;
}

div.main-content {
    height: 100%;
    background-color: white;
    overflow: auto;
    padding-bottom: 20px;
    padding-right: 20px;
}

div.page-row {
    /* float: left; */
    margin-left: 50px;
    margin-top: 20px;
    text-align: left;
}

div.table-content {
    float: left;
    width: 600px;
    margin-top: 20px;
    margin-left: 50px;    
    border: 1px solid #e8e8e8;
    padding: 10px;
    border-radius: 4px;    
}

div.wide-table-content {
    margin: 50px;
    margin-top: 20px;
    border: 1px solid #e8e8e8;
    padding: 10px;
    border-radius: 4px;    
}

div.table-header {
    text-align: left;
    margin-bottom: 5px;   
    padding-left: 20px; 
}

span.table-title {
    font-size: 16px;
    margin-right: 20px;    
}

div.site-mapping {
    text-align: left;
    margin-left: 50px;    
}

.ant-table-small {
    border: none !important;
}

.ant-table-thead > tr > th {
    text-transform: uppercase;
}

.ant-table-tbody > tr > td {
    /* border-bottom: none !important; */
    vertical-align: top;
}

.ant-card-head {
    border-bottom: none !important;
}

div.site-mapped {
    border: 1px solid #52dc65;
    background:#ecffef;
}

div.site-mapped-selected {
    border-width: 0px !important;
    -webkit-box-shadow: 0px 0px 20px 1px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 20px 1px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 20px 1px rgba(0,0,0,0.75);    
}

.btn-login-submit {
    line-height: 1.499;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    white-space: nowrap;
    padding: 0 15px;
    font-size: 14px;
    border-radius: 4px;
    height: 32px;
    user-select: none;
    transition: all .3s cubic-bezier(.645,.045,.355,1);
    position: relative;
    border: 1px solid #d9d9d9;

    color: #fff;
    background-color: #1890ff;
    border-color: #1890ff;
    text-shadow: 0 -1px 0 rgba(0,0,0,.12);
    box-shadow: 0 2px 0 rgba(0,0,0,.045);    
    box-sizing: border-box;
    -webkit-appearance: button;
    width: 200px;
}

.main {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);    
}

div.site-id {
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
    position: absolute;
    bottom: 5px;
    right: 5px;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}